.itr-midcard {
  background: #ffffff;
  border: 1px solid #c7c7c7;
  border-radius: 10px;
  list-style: none;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: row;

}

.itr-midcard-link {
  text-decoration: none;
}

.itr-midcard-data {
  display: flex;
  flex-direction: column;
}

.itr-midcard-title {
  font-family: "Nunito Sans", serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 38px;
  color: #000000;
  padding-left: 5px;
}

.midcard-img-container {
  background: #d9d9d9;
  border-radius: 10px;
  max-width: 30%;
}

.itr-midcard img {
  max-width: 170px;
  object-fit: cover;
  padding: 5px;
}

.itr-midcard-text {
  font-family: "Nunito", serif;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 25px;
  display: block;
  color: #505050;
  padding: 5px;
  max-height: 3.8em;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.service-detail-img {
  height: 124px;
  width: 124px;
}

@media screen and (max-width: 550px) {
  .itr-midcard {
    flex-direction: column;
  }
}