.sd-section {
    background: #fff;
}

.sd-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.sd-left {
    max-width: 50%;
    margin-top: 60px;
}

.sd-left-head {
    font-family: "Nunito Sans", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 65px;
    text-align: justify;
    color: #000000;
    padding-left: 40px;
}

.sd-left-paragraph {
    font-family: "Nunito", serif;
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    font-size: 1.2rem;
    line-height: 29px;
    color: #000000;
    max-width: 90%;
    margin: auto;
    padding-top: 20px;
    white-space: pre-line;
    vertical-align: bottom;
}

.sd-btn {
    margin-left: 30px;
    margin-top: 20px;
    padding: 15px 25px;
    color: #f1f6f9;
    font-size: 1rem;
    background: rgba(23, 70, 162, 0.8);
    box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
}

.sd-btn:hover {
    background-color: rgb(114, 114, 255);
    cursor: pointer;
}

.sd-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    padding: 20px;
}

.sdimg {
    width: 600px;
    border-radius: 10px;
    object-fit: contain;
}

.sd-bottom {
    margin-top: 30px;
}

.sd-bottom-head {
    font-family: "Nunito Sans", serif;
    font-weight: 700;
    font-size: 3rem;
    line-height: 65px;
    text-align: center;
    color: #000000;
}

.sd-card-wrapper {
    display: grid;
    margin: auto;
    max-width: 90%;
    margin-top: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
}

@media screen and (max-width: 768px) {


    .sd-container {
        flex-direction: column-reverse;
        justify-content: space-around;
    }

    .sd-left {
        max-width: 100%;
    }

    .sd-left-head {
        text-align: center;
        padding-left: 0;
    }

    .sd-right {
        margin-top: 20px;
    }

    .sdimg {
        width: 90%;
    }

    .sd-card-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}

.sd-last-head {
    text-align: center;
    padding-bottom: 30px;
    padding-top: 50px;
    font-family: "Nunito Sans", serif;
}