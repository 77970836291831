.statistics-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    flex-wrap: wrap;
    margin: 50px 20px 5px;
}

.stat-item {
    text-align: center;
    flex: 1 1 80px; /* Allows items to shrink and grow */
    margin: 10px;
}

.stat-value {
    font-size: 2rem;
    font-weight: bold;
    color: #000;
}

.stat-label {
    font-size: 1rem;
    color: #7d7d7d;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .statistics-container {
        flex-direction: column;
    }

    .stat-value {
        font-size: 1.5rem;
    }

    .stat-label {
        font-size: 0.875rem;
    }
}

@media (max-width: 480px) {
    .stat-item {
        flex: 1 1 100%;
    }

    .stat-value {
        font-size: 1.2rem;
    }

    .stat-label {
        font-size: 0.75rem;
    }
}
