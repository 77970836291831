.privacy {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .privacy h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .privacy h2 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .privacy h3 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  
  .privacy ul {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 20px;
  }
  
  .privacy li {
    margin-bottom: 5px;
  }
  
  .privacy a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy a:hover {
    text-decoration: underline;
  }
  