/*noinspection CssUnknownTarget*/
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  position: sticky;
  top: 0;
  z-index: 50;
}

.ham-berger-container {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: white;
  margin: 6px 0;
  transition: 0.4s;
}

/* Rotate first bar */
.ham-berger-container-close .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

/* Fade out the second bar */
.ham-berger-container-close .bar2 {opacity: 0;}

/* Rotate last bar */
.ham-berger-container-close .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding-left: 20px;
  padding-right: 20px;

}

.navmainlink {
  text-decoration: none;
}
.nav-logo{
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;

}

.nav-logo img{
  height: 48px;
}

.nav-links {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  text-decoration: none;
}

.nav-logo > img{
  width: 5rem;
}

.nav-logo > p {
  font-family: "Nunito", serif;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.item {
  text-decoration: none;
  text-align: center;
  font-size: 1.25rem;
  font-family: "Nunito", sans-serif;
  padding: 10px 15px;
  margin: auto;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.item:hover {
  color: #ff731d;
  font-weight: bold;
}

.mobile-menu-icon {
  display: none;
}

.nav-links-mobile {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  list-style: none;
  background-color: #5BBCFF;
  z-index: 3;
}

.nav-btn {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border: none;
}

.nav-btn:hover {
  background-color: rgb(114, 114, 255);
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .mobile-menu-icon {
    display: flex;
    font-size: 2.35rem;
    color: #fff;
    background-color: transparent;
    border: none;
    outline: none;
    justify-content: flex-end;
    cursor: pointer;
  }

  .nav-links-mobile {
    top: 80px;
    right: 0;
    width: 100%;
    background-color: #102C57;
  }

  .item {
    padding: 0;
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .nav-logo > img{
    width: 4.5rem;
  }

  .nav-logo > p {
    font-family: "Nunito", serif;
    color: white;
    text-align: center;
    font-weight: bold;
    padding-left: 20px;
    font-size: 1.4rem;
  }
}



@media (max-width: 620px) {


  .sub-header-items-first-text {
    font-size: 0.8rem;
    line-height: 20px;
    padding: 6px;
  }
  .sub-header-items{
    flex-direction: column;
    align-items: center;
  }

  .sub-header-items img {
    padding: 2px 4px;
    align-self: center;
    color: white;
    filter: grayscale(100%);
  }
}
