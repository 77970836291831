.productqa-card {
  padding: 30px;
  background: #f1f6f9;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 20px;
  min-height: 200px;
  min-width: 200px;
  max-width: 350px;
}

.productqa-card:hover {
  transform: scale(1.05);
}

.productqa-card img {
  max-height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.productqa-card-title {
  margin-top: 12px;
  font-family: "Nunito Sans", serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 33px;
  color: #000000;
}

.productqa-card-para {
  padding-top: 8px;
  font-family: "Nunito", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.15rem;
  line-height: 22px;
  /*text-align: justify;*/
  color: #000000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {

  .productqa-card {
    padding: 30px;
    background: #f1f6f9;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: 20px;
    min-height: 200px;
    min-width: 200px;
    max-width: 90%;
  }

}