.itr-section {
    background: #fff;
}

.itr-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.itr-left {
    max-width: 50%;
    margin-top: 60px;
}

.itr-left-head {
    font-family: "Nunito Sans", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 65px;
    text-align: justify;
    color: #000000;
    padding-left: 40px;
}

.itr-left-para {
    font-family: "Nunito", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 29px;
    color: #000000;
    max-width: 90%;
    margin: auto;
    padding-top: 20px;
    white-space: pre-line;
    vertical-align: bottom;
}

.itr-btn {
    margin-left: 30px;
    margin-top: 20px;
    padding: 15px 25px;
    color: #f1f6f9;
    font-size: 1rem;
    background: rgba(23, 70, 162, 0.8);
    box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
}

.itr-btn:hover {
    background-color: rgb(114, 114, 255);
    cursor: pointer;
}

.itr-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    padding: 20px;
}

.itrimg {
    width: 100%;
    object-fit: contain;
}

.itr-middle {
    margin-top: 30px;
}

.itr-middle-head {
    font-family: "Nunito Sans", serif;
    font-weight: 700;
    font-size: 3rem;
    line-height: 65px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
}

.itr-midcard-wrapper {
    display: grid;
    gap: 30px;
    margin: 30px auto auto;
    max-width: 80%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.itr-bottom {
    margin-top: 30px;
}

.itr-bottom-head {
    font-family: "Nunito Sans", serif;
    font-weight: 700;
    font-size: 3rem;
    line-height: 65px;
    text-align: center;
    color: #000000;
}

.itr-card-wrapper {
    display: grid;
    max-width: 90%;
    margin: 30px auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
}

@media screen and (max-width: 768px) {
    .itr-container {
        flex-direction: column;
        justify-content: space-around;
    }

    .itr-left {
        max-width: 100%;
    }

    .itr-left-head {
        text-align: center;
        padding-left: 0;
    }

    .itr-right {
        margin-top: 20px;
    }

    .itrimg {
        width: 90%;
    }

    .itr-midcard-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(1, 1fr);
    }

    .itr-card-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}

.itr-last-head {
    text-align: center;
    padding-bottom: 30px;
    padding-top: 20px;
    font-family: "Nunito Sans", serif;
}