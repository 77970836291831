/*noinspection CssUnknownTarget*/
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap");
/*noinspection CssUnknownTarget*/
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

.footer-section {
  background-color: #102C57;
  color: #ffffff;
  margin-top: 50px;
  width: 100%;
}

.footer-top {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.footer-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.branding {
  margin-top: 35px;
}

.logo {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.logo img {
  margin-bottom: 10px;
  height: 80px;

}

.logo-link{
  text-decoration:  none;
}

.logo p {
  font-family: "Nunito", serif;
  color: white;
  text-align: center;
  font-weight: bold;
  padding-left: 20px;
  font-size: 1.6rem;

}

p{
  text-decoration: none;
}

.social-icons img {
  margin-top: 8px;
  border-radius: 10px;
  padding: 5px;
}

.social-icons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.social-icon{
  align-self: center;
}

.link-info {
  margin-top: 45px;
}

.link-head {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 10px;
}

.link-list {
  list-style: none;
}

.link-text {
  text-decoration: none;
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  color: #ffffff;
  padding: 10px;
}

.link-text:hover {
  text-decoration: underline;
}

.service-info {
  margin-top: 45px;
}

.service-list {
  list-style: none;
}

.service-head {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 10px;
}

.service-text {
  text-decoration: none;
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  color: #ffffff;
  padding: 10px;
}

.contact-info {
  margin-top: 45px;
}

.contact-head {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 10px;
}

.contact-list {
  list-style: none;
}

.info-text {
  text-decoration: none;
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  color: #ffffff;
  padding: 3px;
}

.gap-line {
  width: 80%;
  margin: auto;
  margin-top: 35px;
}

.policy-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
  margin-top: 25px;
  padding: 10px;
  list-style: none;
}

.policy-text {
  text-decoration: none;
  color: #ffff;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 25px;
  text-transform: capitalize;
  list-style: none;
  margin-right: 10px;
  text-align: center;
  padding: 6px;
}

.policy-text:hover {
  text-decoration: underline;
}

.copyright-info {
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.copyright-text {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 25px;
  text-transform: capitalize;
  list-style: none;
  text-align: center;
  padding: 6px;
}

@media screen and (max-width: 968px) {
  .footer-section {
    height: auto;
  }

  .footer-top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
  }

  .email-info {
    margin-top: 30px;
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  }

  .gap-line {
    margin-top: 15px;
  }

  .email-info {
    margin-top: 45px;
  }

  .policy-info {
    margin-top: 5px;
  }

  .copyright-info {
    margin-top: -15px;
  }
}

@media screen and (max-width: 768px) {
  .footer-top {
    display: grid;
    grid-template-columns: 220px 220px;
    grid-row: auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .contact-head,
  .info-text,
  .service-head,
  .service-text {
    padding: 0;
  }
}

@media screen and (max-width: 450px) {
  .footer-top {
    grid-template-columns: 200px;
    align-items: center;
    justify-content: center;
  }

  .social-icons {
    margin-top: 0;
  }

  .policy-info {
    flex-direction: column;
    margin-top: 0;
  }
}
