.hero-section {
  padding: 4vw;
}


.hero-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}

.hero-left{
  flex: 1;
}


.hero-left-text {
  font-family: "Nunito Sans", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 85px;
  color: #000000;
}

.hero-left-sub{
  min-height: 50px;
  font-family: "Nunito Sans", serif;
  font-style: normal;
  padding-top: 12px;
  font-size: 30px;

}

.hero-left-para{
  font-family: "Nunito Sans", serif;
  font-style: normal;
}


.hero-left-text span {
  color: #ff731d;
}

.hero-btn {
  margin-top: 20px;
  padding: 15px 25px;
  color: #f1f6f9;
  font-size: 1rem;
  background: rgba(23, 70, 162, 0.8);
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
}

.hero-btn:hover {
  background-color: rgb(114, 114, 255);
  cursor: pointer;
}

.hero-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heroimg {
  width: 60%;
  object-fit: contain;
}

.hero-right-textbox {
  max-width: 65%;
}

.hero-right-text {
  font-family: "Nunito", serif;
  font-size: 1.7rem;
  text-align: justify;
  color: #000000;
}

@media only screen and (max-width: 600px) {
  .hero-container {
    flex-direction: column;
  }

  .hero-left-text {
    font-size: 3rem;
    line-height: 60px;
    color: #000000;
    /*padding: 16px;*/
    margin-top: 10px;
    /*text-align: center;*/
  }

  .hero-right {
    margin-top: 20px;
  }

  .hero-right-text {
    /*text-align: center;*/
  }
  .hero-left-sub{
    /*text-align: center;*/
    padding-left: 20px;
    font-size: 1.3em;

  }
  .hero-left-para{
    /*text-align: center;*/
    padding-left: 20px;
    font-size: 1.1em;
  }
  .hero-btn{
    margin-left: 20px;
  }

}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .hero-container {
    flex-direction: column;
  }

  .hero-left {
    margin: auto;
  }

  .hero-left-text {
    font-size: 4rem;
    line-height: 70px;
    color: #000000;
    /*padding: 30px 30px 30px 30px;*/
    margin-top: 10px;
  }

  .hero-right {
    margin-top: 20px;
  }

  .hero-right-text {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .hero-container {
    flex-direction: column;
  }

  .hero-left {
    margin: auto;
  }

  .hero-left-text {
    font-size: 4.5rem;
    line-height: 90px;
    color: #000000;
    /*padding: 50px 40px 20px 40px;*/
    margin-top: 10px;
  }

  .hero-right {
    margin-top: 20px;
  }

  .hero-right-text {
    text-align: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-left-text {
    /*padding: 30px 40px 20px 40px;*/
    margin-top: 20px;
  }

  .hero-right-text {
    text-align: center;
  }
}