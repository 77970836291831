.popular-service {
    display: flex;
    gap: 30px;
    font-family: "Nunito Sans", serif;
    padding-left: 8vw;
    padding-right: 8vw;
    padding-bottom: 20px;
    overflow-x: scroll;
    flex-wrap: nowrap;

}

.card {
    flex: 0 0 auto;
    width: 300px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    resize: none;
}

.card-frame {
    position: relative;
    overflow: hidden;
}

.card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.card-content {
    padding: 16px;
}

.card-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.card-description {
    margin-top: 8px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #666;
}

.service-card-link{
    text-decoration: none;
}