.product-card {
  padding: 15px;
  background: #f1f6f9;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 20px;
}

.product-card:hover {
  background-color: rgba(23, 70, 162, 1);
  color: white;
}

.product-card:hover .product-card-title,
.product-card:hover .product-card-link,
.product-card:hover .product-card-para,
.product-card:hover .product-card-link-text {
  color: white !important;
}

.product-card-title {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  color: #000;
  font-size: 1.6rem;
  line-height: 38px;
  padding-top: 10px;
}

.product-card-para {
  font-family: "Nunito";
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 27px;
  /*text-align: justify;*/
  color: #505050;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-card-link {
  font-family: "Nunito";
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 27px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: rgba(23, 70, 162, 1);
  text-align: center;
  text-decoration: none;
}

.product-card-link-text {
  margin-top: 40px;
}