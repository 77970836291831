.security-section {
  background: #fff;
  margin-top: 20px;
}

.security-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.security-left {
  max-width: 50%;
}

.security-left-text {
  font-family: "Nunito Sans", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 85px;
  color: #000000;
  padding: 20px 0 20px 50px;
  margin-top: 60px;
}

.security-left-text span {
  color: #ff731d;
}

.security-left-para {
  font-family: "Nunito", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 46px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #505050;
  max-width: 90%;
  margin: auto;
  padding: 10px;
}

.security-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.securityimg {
  width: 100%;
  object-fit: contain;
  margin-top: 40px;
}

.security-bottom {
  margin-top: 40px;
}

.security-bottom-card {
  max-width: 80%;
  margin: auto;
  display: grid;
  gap: 20px;
}

@media (min-width: 600px) {
  .security-bottom-card {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .security-bottom-card {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .security-container {
    flex-direction: column;
  }
  .security-left {
    max-width: 100%;
  }
  .security-left-text {
    font-size: 3rem;
    line-height: 55px;
    margin: auto;
    max-width: 90%;
    padding-left: 20px;
  }
  .security-left-para {
   max-width: 85%;
   line-height: 40px;
  }
  .securityimg{
    width : 75%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) 
{
  .security-container {
    flex-direction: column;
  }
  .security-left {
    max-width: 100%;
  }
  .security-left-text {
    font-size: 4rem;
    line-height: 65px;
    margin: auto;
    max-width: 90%;
    padding-left: 20px;
  }
  .security-left-para {
    max-width: 85%;
    line-height: 40px;
   }
   .securityimg{
     width : 75%;
   }
}

@media only screen and (min-width: 768px) and (max-width: 992px) 
  {
    .security-container {
      flex-direction: column;
    }
    .security-left {
      max-width: 100%;
    }
    .security-left-text {
      font-size: 4.5rem;
      line-height: 85px;
      margin: auto;
      max-width: 90%;
      padding-left: 20px;
    }
    .security-left-para {
      max-width: 85%;
      line-height: 40px;
     }
     .securityimg{
       width : 75%;
     }
}

@media only screen and (max-width: 1200px) {
  .security-bottom-card {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .security-bottom-card {
    grid-template-columns: repeat(1, 1fr);
  }
}
