.terms-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.5;
}

.terms-container h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.terms-container h2 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
}

.terms-container p {
  margin-bottom: 15px;
}

.terms-container a {
  color: #337ab7;
  text-decoration: none;
}

.terms-container a:hover {
  text-decoration: underline;
}
