.security-card {
  padding: 20px;
  background: #f1f6f9;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 20px;
}

.security-card:hover {
  transform: scale(1.05);
  z-index: 1;
}

.security-card-head {
  font-family: "Nunito Sans", serif;
  font-style: normal;
  font-weight: 800;
  font-size: 1.75rem;
  line-height: 46px;
  letter-spacing: 0.01em;
  text-align: justify;
  color: #000000;
  padding: 10px;
}

.security-card-text {
  font-family: "Nunito", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #000000;
  padding: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width : 768px) {
  .security-card {
    max-width: 350px;
    margin: auto;
    margin-bottom: 30px;
  }
}